import React from 'react'

const order_product = () => {
  return (
    <div>
      <h1>hii</h1>
    </div>
  )
}

export default order_product
