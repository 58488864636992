import React, { useEffect, useState } from "react";
import TableComp from "../components/table/tables";
import TitleComp from "../components/Title/title";
import axios from "axios";
import { Avatar, Button, Switch, Tooltip, message } from "antd";
import DrawerComp from "../components/Drower/Drawer";
import { useDrawer } from "../context/drawer";
import { useSelector } from "react-redux";
import moment from "moment";

export default function AccountDetails() {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed:"left"
    },

    {
      title: "Uername",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Registred Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Login Type",
      dataIndex: "usertype",
      key: "usertype",
    },

    {
      title: "",
      dataIndex: "view",
      key: "",
    },
    {
      title: "",
      dataIndex: "remove",
      key: "",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageComp, setPageComp] = useState(1);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);

  // =========================================== Drawer Context =========================

  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);

  const fetchData = async (page, limit, search) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);
      const response = await axios.post(
        "https://delight.shreekrishnadigital.com/api/user-detail.php?action=fetch&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          "",
        formData
      );
      if (response.data) {
        setLoading(false);
        const updatedData = response.data.list.map((item) => ({
          ...item,
          usertype:
            item.usertype !== "admin" ? item.branch.name : item.usertype,
          created_at: moment(item.created_at).format("llll"),
          view: (
            <Tooltip title="View Account Details" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => handleDrawerUpdate(item)}
              >
                View
              </Button>
            </Tooltip>
          ),
        }));
        setFetchList(updatedData);
        setTotalData(response.data.total);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "");
    // eslint-disable-next-line
  }, [pageComp, limitComp]);

  // ======================= Handle Drawer Update =================================

  const handleDrawerUpdate = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "accountUpdate",
      title: "Update Account",
      value: value,
    });
  };

  // ======================= Handle Drawer Add =================================

  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "accountAdd",
      title: "Account Add",
      ID: null,
    });
  };

  return (
    <div>
      <TitleComp
        name="Account List"
        handleDrawerAdd={handleDrawerAdd}
        totalData={10}
        type="Account"
      />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
      <DrawerComp />
    </div>
  );
}
