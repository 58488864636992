import { Empty } from "antd";
import React from "react";

export default function EmptyComp() {
  return (
    <div>
      <Empty
        image="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg?size=626&ext=jpg&ga=GA1.1.1826414947.1698883200&semt=ais"
        imageStyle={{
          height: 300,
        }}
        description={
          <h3>
             No data found !
          </h3>
        }
      />
    </div>
  );
}
