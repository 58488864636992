import { Button, Col, Divider, List, Row } from "antd";
import { Typography } from "antd";
import React from "react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { MdCall, MdEmail } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import { BsCurrencyRupee } from "react-icons/bs";
import moment from "moment";
import { useDrawer } from "../../../context/drawer";
import { RiErrorWarningFill } from "react-icons/ri";

export default function EnachView() {
  const { Title, Text } = Typography;
  const { drawerData } = useDrawer();

  const checkStatus = (status) => {
    if (status === "success") {
      return "green";
    } else if (status === "failed") {
      return "red";
    } else {
      return "orange";
    }
  };

  const checkStatusIcon = (status) => {
    if (status === "success") {
      return <AiOutlineCheckCircle />;
    } else if (status === "failed") {
      return <AiOutlineCloseCircle />;
    } else {
      return <RiErrorWarningFill />;
    }
  };

  return (
    <div>
      <Row>
        <Col span={12}>
          <Title level={5}>Order Number</Title>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={12}>
          <Title className="valueBooking" level={5}>
            {drawerData.value.Order_id}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Title level={5}>Booking Status</Title>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={12}>
          <Button
            shape="round"
            className="checkStatus"
            icon={checkStatusIcon(drawerData.value.payment_status)}
            style={{
              textTransform: "capitalize",
              background:checkStatus(drawerData.value.payment_status),
              border: "unset !important",
            }}
          >
            {drawerData.value.payment_status ? drawerData.value.payment_status : "Pending"}
          </Button>
        </Col>
      </Row>
      <Divider orientation="left">User Details</Divider>
      <Row>
        <Col span={24}>
          <Title
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
            }}
            level={5}
          >
            {drawerData.value.userName}
          </Title>
        </Col>
        <Col span={12}>
          <Text
            className="valueBooking"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            strong
          >
            <MdCall />
            &nbsp; {drawerData.value.userPhone}
          </Text>
        </Col>
        <Col span={12}>
          <Text
            className="valueBooking"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            strong
          >
            <MdEmail />
            &nbsp; {drawerData.value.userEmail}
          </Text>
        </Col>
      </Row>
      <Divider orientation="left">Branch Detail</Divider>
      <Row>
        <Col span={24}>
          <Title
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
            }}
            level={5}
          >
            {drawerData.value.branchName}
          </Title>
        </Col>
        <Col span={12}>
          <Text
            className="valueBooking"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            strong
          >
            <MdCall />
            &nbsp; {drawerData.value.branchPhone}
          </Text>
        </Col>
        <Col span={12}>
          <Text
            className="valueBooking"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            strong
          >
            <MdEmail />
            &nbsp; {drawerData.value.branchEmail}
          </Text>
        </Col>
        <Col span={24}>
          <Text
            className="valueBooking"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            strong
          >
            <MdEmail />
            &nbsp; {drawerData.value.branchAddress}
          </Text>
        </Col>
      </Row>

      {/* ================= eNach Details =========== */}
      <Divider orientation="left">Food Details</Divider>

      {drawerData.value.foods.map((item, i) => (
        <Row key={i}>
          <Col span={10}>
            <Text level={5}>
              {item.Name} x {item.food_qty}
            </Text>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}  span={7}>
            <Text level={5}>({item.food_spice})</Text>
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }} span={7}>
            <Text className="valueBooking" level={5}>
              ${item.price}
            </Text>
          </Col>
        </Row>
      ))}

      <Divider orientation="left">Booking Details</Divider>
      {/* ======================= Total Detail ================= */}
      <Row>
      <Col span={10}>
          <Text level={5}>Pickup Note</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.pickup_note}
          </Text>
        </Col>
        <Col span={10}>
          <Text level={5}>Booking Date & Time</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {moment(drawerData.value.created_at).format("llll")}
          </Text>
        </Col>

        <Col span={10}>
          <Text level={5}>Subtotal</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            
            ${drawerData.value.food_total}
          </Text>
        </Col>

        <Col span={10}>
          <Text level={5}>20% Tax</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            ${drawerData.value.sales_tax}
          </Text>
        </Col>

        <Col span={10}>
          <Text level={5}>Tip</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            ${drawerData.value.tip}
          </Text>
        </Col>
      </Row>
      <Divider />
      <Row>
      <Col span={10}>
          <Text level={5}>Total</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            ${drawerData.value.total_amount}
          </Text>
        </Col>
      </Row>
    </div>
  );
}
