import { Button, Divider, Form, Input, InputNumber, List, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import axios from "axios";
export default function ProductSize({
  productList,
  setProductList,
  setActiveButton,
}) {
  const [productSizeList, setProductSizeList] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const [form] = useForm();
  console.log(productList);
  const mainproductlist =productList
  
  const handleAddSize = (values) => {
    const previousId = productSizeList.length > 0 ? productSizeList[productSizeList.length - 1].id : 0;
    const sizeData = {
      id: previousId + 1,
      width: values.width,
      height: values.height,
      weight: values.weight, // Include weight in size data
      color: [],
    };
    console.log(sizeData);
    const previousData = productSizeList;
    console.log(previousData)
    previousData.push(sizeData);
    setProductSizeList([...previousData]);
    setProductList((prev) => ({ ...prev, size: [...previousData] }));
    form.resetFields();
  };


  const sendProductDetails = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("action", "AddSize");
      formDataToSend.append("token", token);
      formDataToSend.append("product_id",mainproductlist.productId);
        formDataToSend.append(`productSize`, JSON.stringify(productSizeList));
        formDataToSend.append("Weight", productSizeList[0].weight)
      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formDataToSend
      );

      console.log("API response:", response.data);

      setProductList((prev) => ({
        ...prev,
        size: [...productSizeList],
      }));

      setProductSizeList([]); // Clear the size list after successful API call
      form.resetFields();
      setActiveButton("color");
    } catch (error) {
      console.error("Error sending product details to the API:", error);
      // Handle errors, display an error message, or take appropriate action.
    }
  };
  return (
    <>
      <List
        header={<h5>Product Sizes</h5>}
        dataSource={productSizeList}
        style={{ minHeight: "500px" }}
        renderItem={(item, index) => (
          <List.Item>
            <h6>
            ( {index + 1})&nbsp;{item.width}x{item.height} weight:{item.weight}
            </h6>
            <Button danger>
              <FaTrashAlt />
            </Button>
          </List.Item>
        )}
      />

      <Form
        form={form}
        onFinish={handleAddSize}
        layout="vertical"
        className="mt-5"
        style={{ position: "sticky", bottom: "0", background: "#fff" }}
      >
        <Divider />
        <h5>Add new Size</h5>
        <Space>
          <Form.Item
            name="width"
            rules={[{ required: true, message: "Width is required" }]}
          >
            <InputNumber
              placeholder="width"
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>
          <Form.Item
            name="height"
            rules={[{ required: true, message: "Height is required" }]}
          >
            <InputNumber
              placeholder="height"
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>
          <Form.Item
            name="weight" // Corrected name to match with the form data
            rules={[{ required: true, message: "Weight is required" }]}
          >
            <InputNumber
              placeholder="Weight"
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ background: "green", color: "#fff" }}
              htmlType="submit"
            >
              Add
            </Button>
          </Form.Item>
        </Space>

        <Form.Item className="mt-4">
          <Button
            onClick={() => setActiveButton("product")}
            style={{ color: "#fff", background: "#000" }}
          >
            Previous
          </Button>
          <Button
           onClick={sendProductDetails}
            disabled={productSizeList.length === 0}
            style={{ color: "#fff", background: "green" }}
            type="submit"
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
