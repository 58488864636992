import React, { useEffect, useState } from "react";
import { Button, Upload } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import "react-quill/dist/quill.snow.css";
import { PlusOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { useAccountDetailMutation } from "../../../services/userAuthAPI";

export default function UpdateAccount() {
  const { drawerData, toggleDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);

  const [accountDetail] = useAccountDetailMutation();
  const [form] = Form.useForm();

  //   ============================== Call Update API ========================
  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("name", values.name);
      if (values.password) {
        formData.append("password", values.password);
      }
      // formData.append("phone", values.phone);
      formData.append("email", values.email);
      formData.append("action", "update");

      setLoading(true);

      const response = await accountDetail(formData);
      console.log(response);
      if (response.data) {
        setLoading(false);
        form.resetFields();
        toggleDrawer();
        message.success("Submit success!");
      }
    } catch (error) {
      setLoading(false);
      message.error("Add failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  //   ====================== Data Set ===================

  useEffect(() => {
    if (drawerData.value !== null) {
      form.setFieldsValue({
        id: drawerData.value.id,
        name: drawerData.value.name,
        email: drawerData.value.email,
        phone: drawerData.value.phone,
        usertype: drawerData.value.uertype,
      });
    }
    // eslint-disable-next-line
  }, [drawerData]);

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="id"
          label="Id"
          style={{
            margin: "3px 4px",
            display: "none",
          }}
          hasFeedback
        >
          <Input placeholder="Id" />
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
        >
          <Input placeholder="User Name" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Username"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Username" />
        </Form.Item>
 
        {/* <Form.Item
          name="phone"
          label="Phone"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Username" />
        </Form.Item> */}

        <Form.Item
          name="password"
          label="Password"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
        >
          <Input.Password
            placeholder="Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          label="Confirm Password"
          dependencies={["password"]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
