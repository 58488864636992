  import React, { useEffect, useState } from "react";
  import { Alert, Button, InputNumber, Select, Upload } from "antd";
  import { Form, Input, message, Space } from "antd";
  import { useDrawer } from "../../../context/drawer";
  import "react-quill/dist/quill.snow.css";
  import { PlusOutlined,UploadOutlined } from "@ant-design/icons";
  import { useFoodDetailMutation } from "../../../services/userAuthAPI";
  import { useSelector } from "react-redux";

  import "react-quill/dist/quill.snow.css";
  import axios from "axios";
  import TextArea from "antd/es/input/TextArea";
  import Product from "./Product";

  // ================= Image Validation ==================

  export default function AddBranches() {
    const { toggleDrawer } = useDrawer();
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState(null);
    const [foodDetail] = useFoodDetailMutation();
    const [branchList, setBranchList] = useState();
    const [categoryList, setCategoryList] = useState();
    const [imgError, setImgError] = useState({ message: null, status: null });
    const [step, setStep] = useState(1); // Track the current step
    const [productId, setProductId] = useState(null); // Store the product ID for the second step
    const [selectedSizeId, setSelectedSizeId] = useState(null);
    
    ///=====alldata ===//
    const [productData, setProductData] = useState({
      name: "",
      description: "",
      categoryType: "",
      image: null,
      sizes: [],
    });
    
    const addSize = (size) => {
      setProductData((prevData) => ({
        ...prevData,
        sizes: [...prevData.sizes, size],
      }));
    };
    
    const addColor = (sizeIndex, color) => {
      setProductData((prevData) => {
        const updatedSizes = [...prevData.sizes];
        updatedSizes[sizeIndex].colors.push(color);
        return {
          ...prevData,
          sizes: updatedSizes,
        };
      });
    };
    
    ///==for step ===
    const nextStep = () => {
      setStep(step + 1);
    };

    const prevStep = () => {
      setStep(step - 1);
    };


    // ================= Check Image Size ===================
    const beforeUpload = (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/avif" ||
        file.type === "image/webp";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG/AVIF/WEBP file!");
        setImgError({
          message: "You can only upload JPG/PNG/AVIF/WEBP file!",
          status: true,
        });
      } else {
        setImgError({
          message: null,
          status: false,
        });
      }
      const isLt2M = file.size / 1024 < 80;
      if (!isLt2M) {
        message.error("Image size larger that 80KB please re-upload");
        setImgError({
          message: "Image size larger that 80KB please re-upload",
          status: true,
        });
      } else {
        setImgError({
          message: null,
          status: false,
        });
      }
      return isJpgOrPng && isLt2M;
    };

  

    const [form] = Form.useForm();
    const { token } = useSelector((state) => state.auth);

    const onFinish = async (values) => {
      try {
        const formData = new FormData();
        formData.append("product_name", values.name);
        if (fileList && fileList[0]?.originFileObj) {
          formData.append("product_img", fileList[0]?.originFileObj);
        } else {
          formData.append("product_img", null);
        }
        formData.append("product_desc", values.description);
        // Generate and append the product code
      const productCode = `product${Math.floor(Math.random() * 10000) + 1}`;
      formData.append("product_code", productCode);
        formData.append("Category_Id", values.Category_Type);
        formData.append("action", "AddProduct");
        formData.append("token", token);

        setLoading(true);

        const response = await foodDetail(formData);

        if (response.data) {
          setLoading(false);
          if (response.data.status === "Failed Name") {
            message.error(response.data.message);
            setImgError({ message: response.data.message, status: true });
          }else {
            // Access the ID from the response
            const productId = response.data.product_id;
            setProductId(productId); // Store the product ID for the second step

            // Proceed to the next step (second form)
            nextStep();

            setImgError({ message: false, status: null });
          }
        }
      } catch (error) {
        setLoading(false);
        message.error("Add failed!");
        // Handle errors
        console.error("Error making POST request", error);
      }
    };
    

    const onFinishFailed = () => {
      message.error("Submit failed!");
    };

    const handleChange = (file) => setFileList(file.file);

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
    );

    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e?.fileList;
    };

    // =============== onSearch Category ===================
    const filterOption = (input, option) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const onBranchSelect = (id) => {
      const categoriesList = branchList.filter((value) => value.id === id);
      setCategoryList(categoriesList[0].categories);
    };

    // ================= Second Step - Add Product Size ========================
    const onFinishSize = async (values) => {
      try {
        const formDataSize = new FormData();
        formDataSize.append("product_width", values.productWidth);
        formDataSize.append("product_height", values.productHeight);
        formDataSize.append("product_id", productId); // Use the product ID from the first step
        formDataSize.append("action", "AddSize");
        formDataSize.append("token", token);
        setLoading(true);

        const responseSize = await foodDetail(formDataSize);

        if (responseSize.data) {
          const sizeId = responseSize.data.size_id;
          setSelectedSizeId(sizeId); // Store the size ID for use in the third step
          form.resetFields();
          nextStep(); // Proceed to the next step (third form)
          setLoading(false);
          message.success("Product size added successfully!");
        }
      } catch (error) {
        setLoading(false);
        message.error("Failed to add product size!");
        console.error("Error making POST request for product size", error);
      }
    };
    const onFinishFailedSize = () => {
      message.error("Submit failed for product size!");
    };
    ///===color ===//
    const onFinishColor = async (values) => {
      try {
        const formDataColor = new FormData();
        formDataColor.append("color_name", values.colorName);
        formDataColor.append("color_code", values.colorCode);
        formDataColor.append("product_qty", values.productQty);
        formDataColor.append("product_feature", values.productFeature);
        formDataColor.append("product_price", values.productPrice);
        formDataColor.append("product_size_id_reff", 1); // Use the selected size ID from the second step
        formDataColor.append("created_at", new Date().toISOString());
        formDataColor.append("action", "AddColor");
        formDataColor.append("token", token);

        setLoading(true);

        const responseColor = await foodDetail(formDataColor);


        if (responseColor.data) {
          // Store color data for further use if needed
          setColorFormData(responseColor.data);
          form.resetFields();
          toggleDrawer(); // Close the drawer after the third step
          message.success("Product color added successfully!");
        }
      } catch (error) {
        message.error("Failed to add product color!");
        console.error("Error making POST request for product color", error);
      }
    };
    const onFinishFailedColor = () => {
      message.error("Submit failed for product color!");
    };
  
    return (
      <>
      <div className="drawerForm">
        {/* {step === 1 && (
        <Form form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off" >
        
            <Form.Item
              label="Product Name"
              name="name"
              rules={[
                { required: true, message: "Please input the product name!" },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Product Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input the product description!",
                },
              ]}
            >
              <Input.TextArea style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Product code"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input the product description!",
                },
              ]}
            >
              <Input.TextArea style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Category Type"
              name="Category_Type"
              rules={[
                { required: true, message: "Please select the category type!" },
              ]}
            >
              <Select style={{ width: "100%" }}>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
              <Space>
                <Button  type="primary" htmlType="submit">
                  Next
                </Button>
              </Space>
            </Form.Item>
          </Form>
          )}
  {step === 2 && (
    <Form form={form} onFinish={onFinishSize} onFinishFailed={onFinishFailedSize} autoComplete="off">
  

    <Form.Item
      label="Product Width"
      name="productWidth"
      rules={[{ required: true, message: "Please input the product width!" }]}
    >
      <InputNumber style={{ width: "100%" }} />
    </Form.Item>

    <Form.Item
      label="Product Height"
      name="productHeight"
      rules={[{ required: true, message: "Please input the product height!" }]}
    >
      <InputNumber style={{ width: "100%" }} />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
      <Space>
        <Button type="primary" htmlType="submit" >
          Add Product Size
        </Button>
      </Space>
    </Form.Item>
  </Form>
  )} 
        {step === 3 && (
          <Form
          form={form}
          onFinish={onFinishColor}
          onFinishFailed={onFinishFailedColor}
          autoComplete="off"
          >
          
            <Form.Item
              label="Color Name"
              name="colorName"
              rules={[
                { required: true, message: "Please input the color name!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Color Code"
              name="colorCode"
              rules={[
                { required: true, message: "Please input the color code!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Product Quantity"
              name="productQty"
              rules={[
                { required: true, message: "Please input the product quantity!" },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Product Feature"
              name="productFeature"
              rules={[
                { required: true, message: "Please input the product feature!" },
              ]}
            >
              <Input.TextArea style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Product Price"
              name="productPrice"
              rules={[
                { required: true, message: "Please input the product price!" },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  Add Color
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )} */}
<Product/>
      </div>
      </>
    );
  }
