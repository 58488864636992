import React, { useState } from "react";
import { Alert, Button, Upload } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import "react-quill/dist/quill.snow.css";
import { PlusOutlined } from "@ant-design/icons";
import { useBranchDetailsMutation } from "../../../services/userAuthAPI";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const { TextArea } = Input;

// ================= Image Validation ==================

export default function AddBranches() {
  const { toggleDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [branchDetail] = useBranchDetailsMutation();
  const [contentUpdate, setContentUpdate] = useState();
  const [imgError, setImgError] = useState({ message: null, status: null });

  // ================= Check Image Size ===================
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/avif";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/AVIF file!");
      setImgError({
        message: "You can only upload JPG/PNG file!",
        status: true,
      });
    } else {
      setImgError({
        message: null,
        status: false,
      });
    }
    const isLt2M = file.size / 1024 < 300;
    if (!isLt2M) {
      message.error("Image size larger that 300KB please re-upload");
      setImgError({
        message: "Image size larger that 300KB please re-upload",
        status: true,
      });
    } else {
      setImgError({
        message: null,
        status: false,
      });
    }
    return isJpgOrPng && isLt2M;
  };

  // ================= Submit Branch Form =====================

  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      if(fileList){
      formData.append("branch_img", fileList.originFileObj);
      }
      formData.append("address", values.address);
      formData.append("branchtime", values.branchtime);
      formData.append(
        "branch_slug",
        values.name.toLowerCase().split(" ").join("-")
      );
      formData.append("action", "add");
      formData.append("token", token);

      setLoading(true);

      const response = await branchDetail(formData);

      if (response.data) {
        setLoading(false);
        if (response.data.status === "Already have employee") {
          message.error("Employee Already Registered!");
        } else {
          form.resetFields();
          toggleDrawer();
          message.success("Submit success!");
        }
      }
    } catch (error) {
      setLoading(false);
      message.error("Add failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const handleChange = (file) => setFileList(file.file);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
              {imgError.status && <Alert message={imgError.message} type="error" /> }
        <Form.Item
          label="Upload Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          name="branch_img"
        >
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            accept=".jpeg, .jpg, .png, .avif"
            maxCount={1}
          >
            {uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          name="name"
          label="Branch Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Full Name" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Branch Phone"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
            { min: 10 },
            { max: 10 },
          ]}
        >
          <Input type="number" placeholder="Phone" addonBefore="+1" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Branch Email ID"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
            {
              type: "email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="address"
          label="Branch Address"
          hasFeedback
          style={{
            margin: "3px 4px",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea
            showCount
            maxLength={150}
            style={{
              height: 100,
              resize: "none",
            }}
            placeholder="Address"
          />
        </Form.Item>
        <Form.Item
          name="branchtime"
          label="Branch Times"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactQuill
            theme="snow"
            value={contentUpdate}
            onChange={setContentUpdate}
          />
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
