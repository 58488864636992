import React, { useEffect, useState } from "react";
import { Table, Button, Tooltip } from "antd"; // Import Ant Design Table and Tooltip
import TitleComp from "../components/Title/title";
import { useDrawer } from "../context/drawer";
import DrawerComp from "../components/Drower/Drawer";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

export default function EnachDetail() {
  const columns = [
    {
      title: "order_list_id",
      dataIndex: "order_list_id",
      key: "order_list_id",
    },
    {
      title: "user name",
      dataIndex: "user_id",
      key: "user_id",
      render: (user_id) => {
        const user = userData.find(user => user.User_Id === user_id);
        return user ? user.User_Name : "Unknown"; // Render user name if found, otherwise "Unknown"
      }
    },
    {
      title: "address_id",
      dataIndex: "address_id",
      key: "address_id",
      render: (address_id) => {
        const address = addressData.find(address => address.address_id === address_id);
        console.log(address);
        return address ? address.address_1 : "Unknown"; // Render address if found, otherwise "Unknown"
      }
    },
    {
      title: "Number",
      dataIndex: "user_id",
      key: "user_id",
      render: (user_id) => {
        const user = userData.find(user => user.User_Id === user_id);
        return user ? user.User_Phone : "Unknown"; // Render user name if found, otherwise "Unknown"
      }
    },
    {
      title: "order_status",
      dataIndex: "order_status",
      key: "order_status",
    },
    {
      title: "order_id",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "order_amount",
      dataIndex: "order_amount",
      key: "order_amount",
    },
    {
      title: "payment_mode",
      dataIndex: "payment_mode",
      key: "payment_mode",
    },
    {
      title: "View Invoice",
      key: "view_invoice",
      render: () => (
        <Button
          type="primary"
          onClick={() => window.open("https://jutiepie.in/api/upload/pdf/produtlist.pdf", "_blank")}
        >
          View Invoice
        </Button>
      ),
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState([]);
  const [addressData, setAddressData] = useState([]);

  const fetchData = async (page, limit) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);

      const response = await axios.get(
        `https://jutiepie.in/api/order-list.php?action=productFetch&page=${page}&limit=${limit}&token=${token}`
      );
      if (response.data.orders) {
        const updatedData = response.data.orders.map((item) => ({
          ...item,
          created_at: moment(item.created_at).format("llll"),
          total_amount: (
            <div style={{ display: "flex", alignItems: "center" }}>
              $ &nbsp;{item.total_amount}
            </div>
          ),
          payment_status: item.payment_status ? (
            <span style={{ color: checkStatus(item.payment_status) }}>
              {item.payment_status}
            </span>
          ) : (
            <span style={{ color: "orange", textTransform: "capitalize" }}>
              pending
            </span>
          ),
          view: (
            <Tooltip title="View Order Details" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => handleDrawerView(item)}
              >
                View
              </Button>
            </Tooltip>
          ),
        }));
        setFetchList(updatedData);
        setTotalData(response.data.total);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error making GET request", error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `https://jutiepie.in/api/user.php?action=fetchAllUsers`
      );
      if (response.data) {
        setUserData(response.data.list);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  const fetchAddressData = async () => {
    try {
      const response = await axios.get(
        `https://jutiepie.in/api/address.php?action=backfetchAddresses`
      );
      if (response.data) {
        setAddressData(response.data.data);
        console.log("address", response.data);
      }
    } catch (error) {
      console.error("Error fetching address data", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage, pageSize);
    fetchUserData();
    fetchAddressData();
  }, [currentPage, pageSize]);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <div>
      <TitleComp name="Order Lists" totalData={totalData} type="Order" />
      <Table
        columns={columns}
        dataSource={fetchList}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalData,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
        }}
        rowKey="order_list_id"
      />
      <DrawerComp />
    </div>
  );
}
