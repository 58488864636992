import { Divider } from "antd";
import React, { useState } from "react";
import { Select } from "antd";
import DefaultComponent from "./branchSettingComponent/DefaultComponent";
import SocialMedia from "./branchSettingComponent/SocialMedia";
import DeliveryPartner from "./branchSettingComponent/DeliveryPartner";

export default function OtherSetting() {
    const [selecteSetting, setSelectSetting] = useState("default");

  return (
    <div>
      <Divider orientation="left">Select Setting</Divider>
      <Select
        placeholder="Select Setting for"
         style={{width:"100%"}}
         onSelect={(value)=>setSelectSetting(value)}
        options={[
          { value: "Social Media", label: "Social Media" },
          { value: "Delivery Partner", label: "Delivery Partner" },
        ]}
      />

      {/* ===================== Selected Branch Setting ================= */}
      {selecteSetting === "default" ? <DefaultComponent />: null}
      {selecteSetting === "Social Media" ? <SocialMedia />: null}
      {selecteSetting === "Delivery Partner" ? <DeliveryPartner />: null}
    </div>
  );
}
