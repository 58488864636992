import React, { useState, useEffect } from "react";

import {
  Form,
  Input,
  Button,
  Select,
  Space,
  message,
  Upload,
  InputNumber,
  Popconfirm,
} from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
 
} from "@ant-design/icons";
import { ColorPicker } from 'antd';
import { useSelector } from "react-redux";

import { SketchPicker as CustomColorPicker } from "react-color";
import { Collapse } from "antd";
import { useCategoryDetailMutation } from "../../../services/userAuthAPI";
import axios from "axios";
import AddProduct from "./AddProduct"
const { Panel } = Collapse;

const { Option } = Select;

const Product = () => {

  return (
    <div>
  <AddProduct/>
    </div>
  );
};

export default Product;
