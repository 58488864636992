import React, { useEffect, useState } from "react";
import { Alert, Button, Select, Upload } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import "react-quill/dist/quill.snow.css";
import { PlusOutlined } from "@ant-design/icons";
import { useCategoryDetailMutation } from "../../../services/userAuthAPI";
import { useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";

export default function UpdateForm() {
  const { drawerData, toggleDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([
    {
      uid: "0",
      name: "no image",
      status: "done",
      url: "",
      thumbUrl: "",
    },
  ]);
  const [updateImage, setUpdateImage] = useState(false);
  const [categoryDetail] = useCategoryDetailMutation();
  const [imgError, setImgError] = useState({ message: null, status: null });
  

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/avif";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/AVIF file!");
      setImgError({
        message: "You can only upload JPG/PNG file!",
        status: true,
      });
    } else {
      setImgError({
        message: null,
        status: false,
      });
    }
    const isLt2M = file.size / 1024 < 300;
    if (!isLt2M) {
      message.error("Image size larger than 300KB please re-upload");
      setImgError({
        message: "Image size larger than 300KB please re-upload",
        status: true,
      });
    } else {
      setImgError({
        message: null,
        status: false,
      });
    }
    return isJpgOrPng && isLt2M;
  };

  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);
  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("Category_Id", values.id);
      formData.append("Category_Type", values.name);
      if (updateImage) {
        formData.append("Category_Img", fileList[0].originFileObj);
      } else {
        formData.append("Category_Img", null);
      }
      formData.append("Category_Desc", values.order_category);
      formData.append("action", "update");
      formData.append("slug", values.name.toLowerCase().split(" ").join("-"));
      formData.append("token", token);

      setLoading(true);
      const response = await categoryDetail(formData);

      if (response.data) {
        setLoading(false);
        if (response.data.status === "Failed Name") {
          message.error(response.data.message);
          setImgError({ message: response.data.message, status: true });
        } else {
          form.resetFields();
          toggleDrawer();
          message.success("Update success!");
          setImgError({ message: false, status: null });
        }
      }
    } catch (error) {
      setLoading(false);
      message.error("Update failed!");
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    if (drawerData.value !== null) {
      form.setFieldsValue({
        id: drawerData.value.Category_Id,
        name: drawerData.value.Category_Type,
        order_category: drawerData.value.Category_Desc,
      });

      fileList[0].url = drawerData.value.Category_Img;
      fileList[0].thumbUrl = drawerData.value.Category_Img;
      setFileList([...fileList]);
      setUpdateImage(false);
    }
    // eslint-disable-next-line
  }, [drawerData]);

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setUpdateImage(true);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="id" style={{ display: "none" }}>
          <Input />
        </Form.Item>
        {imgError.status && <Alert message={imgError.message} type="error" />}
        <Form.Item
          label="Upload Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          name="Category_Img"
        >
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            accept=".jpeg, .jpg, .png, .avif"
            maxCount={1}
          >
            {uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          name="name"
          label="Category Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Category Name" />
        </Form.Item>
        <Form.Item
          name="order_category"
          label="Category Order"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Category Order" />
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
