import React from "react";  
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Button, Divider, Tooltip } from "antd";
export default function TitleComp({ name, type, totalData, handleDrawerAdd }) {
  return (
    <div>
      <div className="pageHeader">
        <h2 className="pageTitle">{name}</h2>
        {type === "Order" || type === "Users" ? null : (
          <Tooltip title={"Add New " + type} placement="bottom">
            <Button
              style={{ display: "flex", alignItems: "center" }}
              icon={<AiOutlineCloudUpload />}
              size={55}
              onClick={handleDrawerAdd}
            >
              Add {type}
            </Button>
          </Tooltip>
        )}
      </div>
      <Divider orientation="center">
        Total {type} ({totalData})
      </Divider>
    </div>
  );
}
