import React, { createContext, useContext, useState } from "react";
const DrawerContext = createContext();

export const useDrawer = () => useContext(DrawerContext);

const DrawerProvider = ({ children }) => {
  const [drawerData, setDrawerData] = useState({
    isOpen: false,
    title: null,
    ID: null,
    type: null,
  });
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [deliveryPartners, setDeliveryPartners] = useState([]);

  const toggleDrawer = () =>
    setDrawerData((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  // Value that will be provided to the components consuming this context
  const contextValue = {
    drawerData,
    setDrawerData,
    toggleDrawer,
    socialMediaList,
    setSocialMediaList,
    deliveryPartners,
    setDeliveryPartners,
  };

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
