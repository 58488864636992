import { Empty } from "antd";
import React from "react";

export default function DefaultComponent() {
  return (
    <div className="my-5">
      <Empty
        image="https://img.freepik.com/free-vector/select-concept-illustration_114360-383.jpg?size=338&ext=jpg&ga=GA1.1.1826414947.1699315200&semt=sph"
        imageStyle={{
          height: 200,
        }}
        description={
          <h5>
            Select Setting you looking for
          </h5>
        }
      />
    </div>
  );
}
