import {
  Button,
  ColorPicker,
  Divider,
  Form,
  Input,
  List,
  Select,
  Image,
  Upload,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


export default function ProductImage({
  productList,
  setProductList,
  setActiveButton,
}) {
  const [colorList, setColorList] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [imagesnew, setimagesnew] = useState([]);
  const [sizenew, setsizenew] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [selectedData, setSelectedData] = useState({
    size: null,
    color: null,
    images: [],
  });
  const [form] = Form.useForm();
  const formRef = useRef(x  );
  const mainproductlist = productList.productId;

  console.log(mainproductlist);
  const handleSizeSelect = (value) => {
    const sizeIndex = productList.size.findIndex(
      (valueData) => valueData.id === value
    );
  
    if (sizeIndex !== -1 && productList.size[sizeIndex]) {
      setColorList(productList.size[sizeIndex].color);
      setSelectedSize(value);
    } else {
      console.error('Selected size not found or has no color');
    }
  };

  const handleColorSelect = (value) => {
    setSelectedColor(value);
  };

  const sendColorDetails = async () => {
    try {
      const value = formRef.current.getFieldsValue()
      const formDataToSend = new FormData();
      formDataToSend.append("action", "addImage");
      formDataToSend.append("token", token);
      formDataToSend.append("product_image_type", "demo");
      formDataToSend.append("product_color_id_refff", value.color_id);
      
      // Handle image upload properly
      fileList.forEach((file, index) => {
        formDataToSend.append(`product_image[]`, file.originFileObj);
      });
  
      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formDataToSend
      );
  
      console.log("API response:", response.data);
  
      // Reset form fields upon successful API call
      setActiveButton("image");
    } catch (error) {
      console.error("Error sending product color to the API:", error);
      // Handle errors, display an error message, or take appropriate action.
    }
  };

  
  // const sendColorDetails  lay= async () => {
  //   try {
  //     const formDataToSend = new FormData();
  //     formDataToSend.append("action", "addImage");
  //     formDataToSend.append("token", token);
  //     formDataToSend.append("product_image_type", "demo");
  //     formDataToSend.append("product_color_id_refff ", selectedColor);
  //     formDataToSend.append("product_image", fileList);
  //     const response = await axios.post(
  //       "https://jutiepie.in/api/product.php",
  //       formDataToSend
  //     );

  //     console.log("API response:", response.data);
  //     setActiveButton("image");
  //   } catch (error) {
  //     console.error("Error sending product color to the API:", error);
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://jutiepie.in/api/product.php?action=productSizeFetch&token=${token}&Product_Id=${mainproductlist}`
      );

      if (response) {
        setimagesnew(response.data.data);
        console.log("", response.data.data);
        setsizenew(response.data.data[0].colors);
        console.log("IMAGE", response.data.data[0].colors);
      }
    } catch (error) {
      console.error("Error making get request", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <div>
      <Form onFinish={sendColorDetails} form={form} ref={formRef}>
      <Form.Item
        label="Size"
        name="size_id"
        rules={[{ message: "Please select the  id!" }]}
      >
        <Select onChange={handleSizeSelect} style={{ width: "100%" }}>
          {Array.isArray(imagesnew) ? (
            imagesnew.map((imagesnew) => (
              <Option key={imagesnew.Size_Id} value={imagesnew.Size_Id}>
                Width {imagesnew.Product_Width}* Height
                {imagesnew.Product_Height}
              </Option>
            ))
          ) : (
            <p>No size available</p>
          )}
        </Select>
      </Form.Item>
      <Form.Item
        label="color"
        name="color_id"
        rules={[{ message: "Please select the  id!" }]}
      >
        <Select style={{ width: "100%" }}>
          {Array.isArray(sizenew) ? (
            sizenew.map((sizenew) => (
              <Option
                key={sizenew.product_color_id}
                value={sizenew.product_color_id}
              >
                {sizenew.color_code +
                  " (" +
                  sizenew.product_qty +
                  ") ₹" +
                  sizenew.product_price}
              </Option>
            ))
          ) : (
            <p>No size available</p>
          )}
        </Select>
      </Form.Item>
      <br />

      <Form.Item
        label="Upload Image"
        valuePropName="fileList"
        name="product_image"
      >
       <Upload
        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        multiple
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: 'none',
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
      </Form.Item>
      <Form.Item>
      <Button
        onClick={() => setActiveButton("color")}
        style={{ color: "#fff", background: "#000" }}
      >
        Previous
      </Button>
      </Form.Item>
      <Form.Item>
      <Button
        disabled={fileList?.length === 0}
        style={{ color: "#fff", background: "green" }}
        onClick={sendColorDetails}
      >
        Upload
      </Button>
      </Form.Item>
      </Form>
    </div>
  );
}
