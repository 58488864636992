import axios from "axios";

import React, { useState, useEffect } from "react";
import {
  Tabs,
  Form,
  Size,
  Input,
  Button,
  InputNumber,
  List,
  Divider,
  Space,
  ColorPicker,
  Select,
  Upload,
} from "antd";
import { useDrawer } from "../../../context/drawer";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
export default function PackageUpdateForm() {
  const { drawerData, setDrawerData } = useDrawer();
  const [selectedColorId, setSelectedColorId] = useState(null);
  const [selectedSizeId, setSelectedSizeId] = useState(null);
  const [colorList, setColorList] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [images, setImges] = useState([]);
  const [indexvalue, setindexvalue] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [colorvalue,setcolorvalue]= useState([]);

  const [form] = Form.useForm();

  const { token } = useSelector((state) => state.auth);
  useEffect(() => {
    form.setFieldsValue({
      name: drawerData.value.Product_Name,
      description: drawerData.value.Product_Desc,
    });
    setSizes(drawerData.value.size);
    //  setColors(drawerData.value.size[0].colors);
    setImges(drawerData.value.size);
  }, [drawerData]);

  console.log("sizes", sizes);
  console.log("color", colors);
  console.log("statevalue", indexvalue);
  console.log("image", images);
  console.log("colorvalue",colorvalue);
  // const newwww = sizes.filter(size => size.Size_Id === selectedSizeId);
  // console.log("newwwww",newwww);
  // console.log("sizeid",selectedSizeId);
  // const colorss = colors;
  // const indesofvalue  = sizes.indexOf(selectedSizeId);
  // console.log("indexof",indesofvalue)
  useEffect(() => {
    console.log("Selected Size ID:", selectedSizeId);

    // Find the index of the selected size based on Size_Id
    const indexOfSelectedSize = sizes.findIndex(
      (size) => size.Size_Id === selectedSizeId
    );
    console.log("Index of Selected Size:", indexOfSelectedSize);
    setindexvalue(indexOfSelectedSize);
  }, [selectedSizeId, sizes]);
  /////////////////product update ////////////////////
  const handleFormSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("Product_Id", drawerData.value.Product_Id); // Assuming Product_Id is the unique identifier
      formData.append("Product_Name", values.name);
      formData.append("Product_Desc", values.description);
      formData.append("token", token);
      formData.append("action", "updateproduct");
      // Add other fields as needed

      // Make API call to update the product
      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formData
      );

      // Handle the response as needed
      console.log("Update API Response:", response.data);

      // You can also update the state or perform any other actions based on the API response
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  /////addsize///
  const sendProductDetails = async (values) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("action", "addProductSizesingle");
      formDataToSend.append("token", token);
      formDataToSend.append("Product_Width", values.width);
      formDataToSend.append("Product_Height", values.height);
      formDataToSend.append("Product_Id", drawerData.value.Product_Id);
      // formDataToSend.append(`productSize`, JSON.stringify(productSizeList));

      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formDataToSend
      );

      console.log("API response:", response.data);

      setProductSizeList([]); // Clear the size list after successful API call
      form.resetFields();
      setActiveButton("color");
    } catch (error) {
      console.error("Error sending product details to the API:", error);
      // Handle errors, display an error message, or take appropriate action.
    }
  };
  ////color///
  const sendcolorDetails = async (values) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("action", "AddColor");
      formDataToSend.append("token", token);

      formDataToSend.append("color_name", values.colorname);
      formDataToSend.append("color_code", "DEM");
      formDataToSend.append("product_qty", values.quantity);
      formDataToSend.append("product_feature", "xyz");
      formDataToSend.append("product_price", values.price);
      formDataToSend.append("Size_Id", selectedSizeId);

      // Generate product code based on the product name

      // Add more fields as needed

      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formDataToSend
      );

      console.log("API response:", response.data);
      // Update this based on the actual response structure

      // Reset form fields upon successful API call

      setActiveButton("image");
    } catch (error) {
      console.error("Error sending product color to the API:", error);
      // Handle errors, display an error message, or take appropriate action.
    }
  };
  ///imge ////
  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };
  const sendColorDetails = async () => {
    console.log("sldnfsdsdcssdmsmsmdmdm");
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("action", "addImage");
      formDataToSend.append("token", token);
      formDataToSend.append("product_image_type", "demo");
      formDataToSend.append("product_color_id_refff", colorvalue);

      // Handle image upload properly
      fileList.forEach((file, index) => {
        formDataToSend.append(`product_image`, file.originFileObj);
      });

      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formDataToSend
      );

      console.log("API response:", response.data);

      // Reset form fields upon successful API call
      setActiveButton("image");
    } catch (error) {
      console.error("Error sending product color to the API:", error);
      // Handle errors, display an error message, or take appropriate action.
    }
  };

  const items = [
    {
      key: "1",
      label: "Product",
      children: (
        <div className="product">
          <Form
            form={form}
            name="wrap"
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            style={{ maxWidth: 600 }}
            onFinish={handleFormSubmit}
          >
            <Form.Item label="Product Name:" name="name" rules={[{}]}>
              <Input />
            </Form.Item>

            <Form.Item label="Product Desc:" name="description" rules={[{}]}>
              <Input />
            </Form.Item>

            <Form.Item label=" ">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: "2",
      label: "Size",
      children: (
        <>
          <List
            header={<h5>Product Sizes</h5>}
            style={{ minHeight: "500px" }}
            dataSource={sizes}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    "Height" +
                    item.Product_Height +
                    "X" +
                    "width" +
                    item.Product_Width
                  }
                />
              </List.Item>
            )}
          />
          <Form
            form={form}
            onFinish={sendProductDetails}
            layout="vertical"
            className="mt-5"
            style={{ position: "sticky", bottom: "0", background: "#fff" }}
          >
            <Divider />
            <h5>Add new Size</h5>
            <Space>
              <Form.Item
                name="width"
                rules={[{ required: true, message: "Width is required" }]}
              >
                <InputNumber
                  placeholder="width"
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
              <Form.Item
                name="height"
                rules={[{ required: true, message: "Height is required" }]}
              >
                <InputNumber
                  placeholder="height"
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ background: "green", color: "#fff" }}
                  htmlType="submit"
                >
                  Add
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </>
      ),
    },
    {
      key: "3",
      label: "Color",
      children: (
        <>
          <Select
            onChange={(value) => {
              setSelectedSizeId(value);

              // Find the index of the selected size based on Size_Id
              const indexOfSelectedSize = sizes.findIndex(
                (size) => size.Size_Id === value
              );
              console.log("Index of Selected Size:", indexOfSelectedSize);

              // Set colors based on the index
              setColors(
                indexOfSelectedSize !== -1
                  ? sizes[indexOfSelectedSize].colors
                  : []
              );
            }}
            value={selectedSizeId}
            style={{ width: "100%" }}
          >
            {Array.isArray(sizes) && sizes.length > 0 ? (
              sizes.map((sizes) => (
                <Option key={sizes.Size_Id} value={sizes.Size_Id}>
                  Width {sizes.Product_Width}* Height{sizes.Product_Height}
                </Option>
              ))
            ) : (
              <p>No size available</p>
            )}
          </Select>

          <List
            header={<h5>Product Colors</h5>}
            dataSource={colors}
            style={{ minHeight: "400px" }}
            renderItem={(item, index) => (
              <List.Item>
                <h6>
                  ( {index + 1})&nbsp;{item.color_name}&nbsp;Qty(
                  {item.product_qty}
                  )&nbsp;
                  {/* <MdOutlineCurrencyRupee /> */}₹{item.product_price}
                </h6>
                <Button danger>{/* <FaTrashAlt /> */}</Button>
              </List.Item>
            )}
          />

          <Form
            // form={form}
            onFinish={sendcolorDetails}
            layout="vertical"
            className="mt-5"
            style={{ position: "sticky", bottom: "0", background: "#fff" }}
          >
            <Divider />
            <h5>Add new colur again</h5>
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="color"
                  //   rules={[{ required: true, message: "color is required" }]}
                >
                  <ColorPicker
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                    }}
                    showText
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="colorname"
                  rules={[
                    { required: true, message: "color name is required" },
                  ]}
                >
                  <Input
                    placeholder="color name"
                    style={{ width: "100%" }}
                    min={0}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: "available quantity is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="quantity"
                    style={{ width: "100%" }}
                    min={0}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="price"
                  rules={[{ required: true, message: "price is required" }]}
                >
                  <Input
                    placeholder="price"
                    style={{ width: "100%" }}
                    min={0}
                  />
                </Form.Item>
              </div>
              <div className="col-md-2">
                <Form.Item>
                  <Button
                    style={{ background: "green", color: "#fff" }}
                    htmlType="submit"
                  >
                    Add
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </>
      ),
    },
    {
      key: "4",
      label: "Image",
      children: (
        <div>
          <Form.Item
            label="Size"
            name="size_id"
            rules={[{ message: "Please select the  id!" }]}
          >
            <Select
              onChange={(value) => {
                setSelectedSizeId(value);

                // Find the index of the selected size based on Size_Id
                const indexOfSelectedSize = sizes.findIndex(
                  (size) => size.Size_Id === value
                );
                console.log("Index of Selected Size:", indexOfSelectedSize);

                // Set colors based on the index
                setColors(
                  indexOfSelectedSize !== -1
                    ? sizes[indexOfSelectedSize].colors
                    : []
                );
              }}
              value={selectedSizeId}
              style={{ width: "100%" }}
            >
              {Array.isArray(sizes) && sizes.length > 0 ? (
                sizes.map((sizes) => (
                  <Option key={sizes.Size_Id} value={sizes.Size_Id}>
                    Width {sizes.Product_Width}* Height{sizes.Product_Height}
                  </Option>
                ))
              ) : (
                <p>No size available</p>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label="color"
            name="color_id"
            rules={[{ message: "Please select the  id!" }]}
          >
            <Select
              onChange={(value) => {
                setcolorvalue(value);

                // Find the index of the selected size based on Size_Id
                const indexOfSelectedSize = sizes.findIndex(
                  (size) => size.Size_Id === value
                );
                const setimaevalue =  (size) => size.Size_Id === value;
                console.log(setimaevalue);
                console.log("Index of Selected Size:", indexOfSelectedSize);

                // Set colors based on the index
                setColors(
                  indexOfSelectedSize !== -1
                    ? sizes[indexOfSelectedSize].colors
                    : []
                );
              }}
              value={selectedSizeId}
              style={{ width: "100%" }}
            >
              {Array.isArray(colors) && colors.length > 0 ? (
                colors.map((colors) => (
                  <Option
                    key={colors.product_color_id}
                    value={colors.product_color_id}
                  >
                    Color : {colors.color_name} Qty:{colors.product_qty}₹:
                    {colors.product_price}
                  </Option>
                ))
              ) : (
                <p>No size available</p>
              )}
            </Select>
          </Form.Item>
          <br />

          <Form.Item
            label="Upload Image"
            valuePropName="fileList"
            getValueFromEvent={(e) => e && e.fileList}
            name="product_image"
          >
            <Upload
              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              listType="picture-card"
              fileList={fileList}
              onChange={handleUploadChange}
              beforeUpload={() => false}
              accept=".jpeg, .jpg, .png, .avif, .webp"
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
          <Button
            onClick={() => setActiveButton("color")}
            style={{ color: "#fff", background: "#000" }}
          >
            Previous
          </Button>
          <Button
            onClick={sendColorDetails()}
            disabled={colorList && colorList.length === 0}
            style={{ color: "#fff", background: "green" }}
            type="submit"
          >
            Upload
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <Tabs items={items} />
        <hr />
      </div>
    </>
  );
}
