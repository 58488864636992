import {
  Avatar,
  Button,
  Divider,
  Empty,
  Form,
  Input,
  List,
  Popconfirm,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import { AiFillMinusCircle } from "react-icons/ai";

import { UploadOutlined } from "@ant-design/icons";
import { useDrawer } from "../../../../context/drawer";
import { useBranchDetailsMutation } from "../../../../services/userAuthAPI";

export default function DeliveryPartner() {
  const [imageFile, setImageFile] = useState(null);

  const props = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        setImageFile(info.file.originFileObj);
      }
      if (info.file.status === "done") {
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const { drawerData, deliveryPartners, setDeliveryPartners } = useDrawer();
  const [branchDetails] = useBranchDetailsMutation();
  const [form] = Form.useForm();

  const handleSubmit = async (value) => {
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("name", value.name);
    formData.append("link", value.url);
    formData.append("branchId", drawerData.value.id);
    formData.append("action", "partnerAdd");

    const res = await branchDetails(formData);

    if (res.data) {
      message.success("Added Successfully");

      const listSocial = deliveryPartners;
      const listData = {
        image: imageFile ? URL.createObjectURL(imageFile) : null,
        name: value.name,
        link: value.url,
      };

      listSocial.push(listData);
      form.resetFields();
      setDeliveryPartners([...listSocial]);
    }
  };

  const removeSocial = async (index, id) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("action", "removePartner");

    const res = await branchDetails(formData);

    if (res.data) {
      message.success("Removed Successfully");
      deliveryPartners.splice(index, 1);
      setDeliveryPartners([...deliveryPartners]);
    }
  };

  return (
    <div>
      <Divider orientation="left">Delivery Partner List</Divider>
      <List itemLayout="horizontal">
        {deliveryPartners && deliveryPartners.length > 0 ? (
          deliveryPartners.map((value, i) => (
            <List.Item key={i}>
              <List.Item.Meta
                style={{ display: "flex", alignItems: "center" }}
                avatar={<Avatar size={50} src={value.image} />}
                title={
                  <div className="d-flex justify-content-between align-items-center">
                    <h5
                      style={{ fontSize: "16px", textTransform: "capitalize" }}
                    >
                      {value.name}
                    </h5>
                    <Popconfirm
                      title="Remove Partner"
                      description="Are you sure to remove this Partner?"
                      onConfirm={() => removeSocial(i, value.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <h6 style={{ color: "red", cursor: "pointer" }}>
                        <AiFillMinusCircle />
                      </h6>
                    </Popconfirm>
                  </div>
                }
              />
            </List.Item>
          ))
        ) : (
          <Empty />
        )}
      </List>

      <Divider orientation="left">Add Delivery Partner</Divider>
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={handleSubmit}
      >
        <Form.Item name="partnerImage">
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Upload Partner Image</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="name"
          label="Enter Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Delivery Partner Name" />
        </Form.Item>
        <Form.Item
          label="Enter URL"
          name="url"
          rules={[
            {
              required: true,
            },
            { type: "url" },
          ]}
        >
          <Input placeholder="Delivery Partner URL" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
