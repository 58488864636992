import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import SideBarContext from "./context/nav-toggle";
import DrawerContext from "./context/drawer";
import axios from "axios";

const root = ReactDOM.createRoot(document.getElementById("root"));
function renderReactDom() {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <SideBarContext>
          <DrawerContext>
            <App />
          </DrawerContext>
        </SideBarContext>
      </Provider>
    </React.StrictMode>
  );
}

const handleKeyStore = async (token) => {
  const formData = new FormData();
  formData.append("notificationToken", token);
  formData.append("device", "android");

  const response = await axios.post(
    "https://delight.shreekrishnadigital.com/api/notification.php",
    formData
  );

  if (response.data) {
    localStorage.setItem("storeToken", true);
  }
};
function firebasePlugin() {
  window.FirebasePlugin.getToken(
    function (token) {
      // save this server-side and use it to push notifications to this device
      console.log(token);
      handleKeyStore(token);
    },
    function (error) {
      console.error(error);
    }
  );

  // Get notified when a token is refreshed
  window.FirebasePlugin.onTokenRefresh(
    function (token) {
      // save this server-side and use it to push notifications to this device
      console.log("Refresh to get new token: " + token);
    },
    function (error) {
      alert(error);
    }
  );

  // Get notified when the user opens a notification
  window.FirebasePlugin.onMessageReceived(
    function (message) {
      console.log(message);
      if (message.messageType === "notification") {
        showNotification(message);
        alert(message.title + " for more detail check in orders page");

        if (message.tap) {
          alert(message.title + " for more detail check in orders list");
        }
      }
    },
    function (error) {
      console.error(error);
    }
  );

  function showNotification(data) {
    if (cordova.plugins.notification.local) {
      cordova.plugins.notification.local.schedule({
        title: data.title,
        text: data.body,
        foreground: !data.tap // Show only if not tapped (background scenario)
      });
    }else{
      console.log("not support");
    }
  }

  renderReactDom();
}

if (window.cordova) {
  document.addEventListener("deviceready", firebasePlugin, false);
} else {
  renderReactDom();
}
