import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register the required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Product Sale',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [lineData, setLineData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Total Sales',
        data: [],
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  });

  const [dailySalesData, setDailySalesData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Daily Product Sales',
        data: [],
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [dailyRevenueData, setDailyRevenueData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Daily Revenue',
        data: [],
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [stateSalesData, setStateSalesData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Sales by State',
        data: [],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    fetch('https://jutiepie.in/api/order-product.php?action=fetchTopSoldProducts')
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          const labels = data.data.map(item => item.Product_Name);
          const values = data.data.map(item => parseInt(item.sold_quantity, 10));

          setBarData({
            labels,
            datasets: [
              {
                label: 'Product Sale',
                data: values,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
          });
        }
      })
      .catch(error => console.error('Error fetching top sold products data:', error));

    fetch('https://jutiepie.in/api/order-product.php?action=fetchMonthlyTotalSales')
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          const labels = data.data.map(item => item.sales_month);
          const values = data.data.map(item => parseInt(item.total_sales, 10));

          setLineData({
            labels,
            datasets: [
              {
                label: 'Total Sales',
                data: values,
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgba(75, 192, 192, 0.2)',
              },
            ],
          });
        }
      })
      .catch(error => console.error('Error fetching monthly total sales data:', error));

    fetch('https://jutiepie.in/api/order-product.php?action=fetchDailyProductSales&token=6ocl8gf4CUQK5UDza4DjygDMadgiG4uEBQWUfFdySX8')
      .then(response => response.json())
      .then(data => {
        const labels = data.map(item => item.Day);
        const values = data.map(item => parseInt(item.Products_Sold, 10));

        setDailySalesData({
          labels,
          datasets: [
            {
              label: 'Daily Product Sales',
              data: values,
              backgroundColor: 'rgba(153, 102, 255, 0.2)',
              borderColor: 'rgba(153, 102, 255, 1)',
              borderWidth: 1,
            },
          ],
        });
      })
      .catch(error => console.error('Error fetching daily product sales data:', error));

    fetch('https://jutiepie.in/api/order-product.php?action=fetchDailyRevenue')
      .then(response => response.json())
      .then(data => {
        const labels = data.map(item => item.Day);
        const values = data.map(item => parseInt(item.Revenue, 10));

        setDailyRevenueData({
          labels,
          datasets: [
            {
              label: 'Daily Revenue',
              data: values,
              backgroundColor: 'rgba(255, 159, 64, 0.2)',
              borderColor: 'rgba(255, 159, 64, 1)',
              borderWidth: 1,
            },
          ],
        });
      })
      .catch(error => console.error('Error fetching daily revenue data:', error));

      fetch('https://jutiepie.in/api/order-product.php?action=fetchProductSalesByState')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          const labels = data.map(item => item.state);
          const values = data.map(item => parseInt(item.total_sales, 10));

          setStateSalesData({
            labels,
            datasets: [
              {
                label: 'Sales by State',
                data: values,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
              },
            ],
          });
        }
      })
      .catch(error => console.error('Error fetching state-wise sales data:', error));
  }, []);

  
  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Top Sold Products',
      },
    },
  };

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Monthly Total Sales',
      },
    },
  };

  const dailySalesOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Daily Product Sales',
      },
    },
  };

  const dailyRevenueOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Daily Revenue',
      },
    },
  };

  const stateSalesOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Sales by State',
      },
    },
  };
  return (
    <div>
      <div className="row">
        <div className="col-6">
          <Bar data={barData} options={barOptions} />
        </div>
        <div className="col-6">
          <Line data={lineData} options={lineOptions} />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Bar data={dailySalesData} options={dailySalesOptions} />
        </div>
        <div className="col-6">
          <Line data={dailyRevenueData} options={dailyRevenueOptions} />
        </div>
        <div className="col-12">
          <Bar data={stateSalesData} options={stateSalesOptions} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
