import React, { useEffect, useState } from "react";
import { Table, Button, Tooltip, message, Spin } from "antd"; // Import Ant Design components
import { DeleteOutlined } from '@ant-design/icons';
import TitleComp from "../components/Title/title";
import { useDrawer } from "../context/drawer";
import DrawerComp from "../components/Drower/Drawer";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

export default function FoodItems() {
  const columns = [
    {
      title: "Product Id",
      dataIndex: "Product_Id",
      key: "Product_Id",
    },
    {
      title: "Product Name",
      dataIndex: "Product_Name",
      key: "Product_Name",
    },
    {
      title: "Product Description",
      dataIndex: "Product_Desc",
      key: "Product_Desc",
    },
    {
      title: "Total Sold",
      dataIndex: "total_sold",
      key: "total_sold",
    },
    {
      title: "Edit",
      dataIndex: "Edit",
      key: "Edit",
      render: (_, record) => (
        <Tooltip title="View Product Details" placement="bottom">
          <Button
            className="otherButtons"
            onClick={() => handleDrawerUpdate(record)}
          >
            Edit
          </Button>
        </Tooltip>
      ),
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      key: "view",
      render: (_, record) => (
        <Tooltip title="Delete Product" placement="bottom">
          <Button
            type="primary"
            shape="circle"
            icon={<DeleteOutlined />}
            size="large"
            onClick={() => handleDelete(record.Product_Id)}
          />
        </Tooltip>
      ),
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);

  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);

  const fetchSoldData = async () => {
    try {
      const response = await axios.get(
        "https://jutiepie.in/api/order-product.php?action=soloProduct"
      );
      if (response.data.status === "success") {
        return response.data.data;
      } else {
        throw new Error("Failed to fetch sold data");
      }
    } catch (error) {
      console.error("Error fetching sold data", error);
      return [];
    }
  };

  const fetchData = async (page, limit) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);
      const [productResponse, soldData] = await Promise.all([
        axios.get(
          `https://jutiepie.in/api/product.php?action=productFetch&page=${page}&limit=${limit}&token=${token}`
        ),
        fetchSoldData(),
      ]);

      if (productResponse.data) {
        setLoading(false);
        setTotalData(productResponse.data.total);
        const productList = productResponse.data.list;

        // Merge total sold data into product list
        const mergedData = productList.map((product) => {
          const soldInfo = soldData.find(
            (sold) => sold.Product_Id === product.Product_Id
          );
          return {
            ...product,
            total_sold: soldInfo ? soldInfo.total_sold : 0,
          };
        });

        setFetchList(mergedData);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error making GET request", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "packageAdd",
      title: "Product Add",
      ID: null,
    });
  };

  const handleDrawerUpdate = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "Updateproduct",
      title: "Update Product",
      value: value,
    });
  };

  const handleDelete = async (productId) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("token", token);
      formData.append("action", "remove");
      formData.append("product_id", productId);

      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formData
      );

      if (response.data.success) {
        message.success("Product deleted successfully");
        // Refresh data after deletion
        fetchData(currentPage, pageSize);
      } else {
        message.error(response.data.message || "Failed to delete product");
      }
    } catch (error) {
      message.error("Failed to delete product");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <div>
      <TitleComp
        name="Product List"
        handleDrawerAdd={handleDrawerAdd}
        totalData={totalData}
        type="Products"
      />
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={fetchList}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalData,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            },
          }}
          rowKey="Product_Id"
          onChange={handleTableChange}
        />
      </Spin>
      <DrawerComp />
    </div>
  );
}
