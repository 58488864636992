import React, { useState } from "react";
import { Button, Radio, Select, Upload } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import "react-quill/dist/quill.snow.css";
import { PlusOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { useAccountDetailMutation } from "../../../services/userAuthAPI";
import axios from "axios";

export default function AddAccount() {
  const { toggleDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  const [accountDetail] = useAccountDetailMutation();
  const [branchList, setBranchList] = useState([]);
  const [typeSelcted, setTypeSelcted] = useState(null);
  const [form] = Form.useForm();

  //   ============================== Call Update API ========================
  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.username);
      formData.append("phone", null);
      formData.append("usertype", typeSelcted);
      formData.append("branch_id",values.branch );
      formData.append("password", values.password);
      formData.append("action", "add");

      setLoading(true);

      const response = await accountDetail(formData);

      if (response.data) {
        setLoading(false);
        form.resetFields();
        toggleDrawer();
        message.success("Submit success!");
      }
    } catch (error) {
      setLoading(false);
      message.error("Add failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  const fetchData = async (page, limit, search) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://delight.shreekrishnadigital.com/api/branch-detail.php?action=fetch&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          ""
      );
      if (response.data) {
        setLoading(false);
        setBranchList(response.data.list);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  const onCheckType = (type) => {
    setTypeSelcted(type)
    if(type === "branch"){
      if(branchList && branchList.length === 0){
        fetchData(0, 10, "");
      }
    }
  }

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label="Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Full Name" />
        </Form.Item>

        <Form.Item
          name="username"
          label="Username"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
            { min: 6 },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="type"
          label="Account Type"
          style={{
            margin: "3px 4px",
            display: "inline-block",
            width: "calc(50% - 8px)",
          }}
          onChange={(e) => onCheckType(e.target.value)}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group optionType="button" buttonStyle="solid">
            <Radio.Button value="admin">Admin</Radio.Button>
            <Radio.Button value="branch">Branch</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="branch"
          label="Select Branch "
          style={{
            margin: "3px 4px",
            display: "inline-block",
            width: "calc(50% - 8px)",
          }}
          hasFeedback
        >
          <Select
            disabled={typeSelcted === "branch" ? false : true}
            placeholder="Select Branch"
          >
            {branchList &&
              branchList.length > 0 &&
              branchList.map((value, i) => (
                <Select.Option key={i} label={value.name} value={value.id}>
                  {value.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password
            placeholder="Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          label="Confirm Password"
          dependencies={["password"]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
