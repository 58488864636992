import React, { useEffect, useState } from "react";
import { Table, Pagination } from "antd"; // Import Ant Design Table and Pagination
import TitleComp from "../components/Title/title";
import DrawerComp from "../components/Drower/Drawer";
import { useSelector } from "react-redux";
import axios from "axios";

export default function UserDetail() {
  const columns = [
    {
      title: "User Id",
      dataIndex: "User_Id",
      key: "User_Id",
    },
    {
      title: "User Name",
      dataIndex: "User_Name",
      key: "User_Name",
    },
    {
      title: "User Email",
      dataIndex: "User_Email",
      key: "User_Email",
    },
    {
      title: "User Phone",
      dataIndex: "User_Phone",
      key: "User_Phone",
    },
    {
      title: "Total Order",
      dataIndex: "Total_Order",
      key: "Total_Order",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalData, setTotalData] = useState(0);
  const { token } = useSelector((state) => state.auth);

  const fetchData = async (page, limit) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);
      const response = await axios.get(
        `https://jutiepie.in/api/user.php?action=fetchAllUsers&page=${page}&limit=${limit}`,
        { data: formData }
      );
      if (response.data) {
        const users = response.data.list;
        const updatedUsers = await Promise.all(
          users.map(async (user) => {
            const totalOrders = await fetchUserTotalOrders(user.User_Id);
            return { ...user, Total_Order: totalOrders };
          })
        );
        setFetchList(updatedUsers);
        setTotalData(response.data.total);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error making GET request", error);
    }
  };

  const fetchUserTotalOrders = async (userId) => {
    try {
      const response = await axios.get(
        `https://jutiepie.in/api/order-list.php?action=fetchOrdersnew&token=${token}&user_id=${userId}`
      );
      if (response.data) {
        return response.data.order_count || 0;
      }
    } catch (error) {
      console.error(`Error fetching total orders for user ${userId}`, error);
    }
    return 0;
  };

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <div>
      <TitleComp name="Users Lists" totalData={totalData} type="Users" />
      <Table
        columns={columns}
        dataSource={fetchList}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalData,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
        }}
        rowKey="User_Id"
      />
      <DrawerComp />
    </div>
  );
}
