import React, { useEffect, useState } from "react";
import { Alert, Button, Upload } from "antd";
import { Form, Input, message, Space, Select } from "antd";
import { useDrawer } from "../../../context/drawer";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useCategoryDetailMutation } from "../../../services/userAuthAPI";
import { useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

export default function AddCategories() {
  const { toggleDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [categoryDetail] = useCategoryDetailMutation();
  const [branchList, setBranchList] = useState();
  const [imgError, setImgError] = useState({ message: null, status: null });

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/avif" ||
      file.type === "image/webp";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/AVIF/WEBP file!");
      setImgError({
        message: "You can only upload JPG/PNG/AVIF/WEBP file!",
        status: true,
      });
    } else {
      setImgError({
        message: null,
        status: false,
      });
    }
    const isLt2M = file.size / 1024 < 80;
    if (!isLt2M) {
      message.error("Image size larger than 80KB, please re-upload");
      setImgError({
        message: "Image size larger than 80KB, please re-upload",
        status: true,
      });
    } else {
      setImgError({
        message: null,
        status: false,
      });
    }
    return isJpgOrPng && isLt2M;
  };

  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("Category_Type", values.name);
      if (fileList) {
        formData.append("Category_Img", fileList.originFileObj);
      } else {
        formData.append("Category_Img", null);
      }
      formData.append("Category_Desc",values.description );
      formData.append("Category_Code", "asd");

      formData.append(
        "   Category_Slugs",
        values.name.toLowerCase().split(" ").join("-")
      );
      formData.append("action", "add");
      formData.append("token", token);

      setLoading(true);

      const response = await categoryDetail(formData);

      if (response.data) {
        setLoading(false);
        if (response.data.status === "Failed Name") {
          message.error(response.data.message);
          setImgError({ message: response.data.message, status: true });
        } else {
          form.resetFields();
          toggleDrawer();
          message.success("Submit success!");
          setImgError({ message: false, status: null });
        }
      }
    } catch (error) {
      setLoading(false);
      message.error("Add failed!");
      console.error("Error making POST request", error);
    }
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const handleChange = (file) => setFileList(file.file);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {imgError.status && <Alert message={imgError.message} type="error" />}
        <Form.Item
          label="Upload Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          name="cat_image"
        >
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            accept=".jpeg, .jpg, .png, .avif, .webp"
            maxCount={1}
          >
            {uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          name="name"
          label="Category Type"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Category Type" />
        </Form.Item>
        
        <Form.Item
          name="description"
          label="Category Description"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea
            showCount
            maxLength={150}
            style={{
              height: 100,
              resize: "none",
            }}
            placeholder="Category Description"
          />
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
