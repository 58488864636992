import React, { useEffect, useState } from "react";
import { Avatar, Button, Col, Divider, List, Modal, Row, Spin } from "antd";
import { Result, Typography } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";
const { Text } = Typography;
import moment from "moment";
import { useParams } from "react-router-dom";

const NotificationAlert = () => {
  const [loading, setLoading] = useState();
  const [fetchList, setFetchList] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const { orderId } = useParams();

  const fetchData = async (ID) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);

      const response = await axios.post(
        "https://delight.shreekrishnadigital.com/api/order-detail.php?action=fetchById&orderId=" +
          ID,
        formData
      );
      if (response.data) {
        setLoading(false);
        setFetchList(response.data.list);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchData(orderId);
    }
  }, [orderId]);

  return (
    <Spin spinning={loading}>
      {fetchList &&
        fetchList.map((value) => (
          <Result
            style={{ padding: "20px" }}
            status="success"
            title={`New Order at ${value.branchName}`}
          >
            <div className="desc">
              <Text
                strong
                style={{
                  fontSize: 18,
                }}
              >
                Order Details
              </Text>
              <div className="orderDetails">
                <div className="row">
                  <div className="col-4">Order Id</div>
                  <div className="col-2">:</div>
                  <div className="col-6 d-flex justify-content-end">
                    {value.Order_id}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">Order Date</div>
                  <div className="col-2">:</div>
                  <div className="col-6 d-flex justify-content-end">
                    {moment(value.created_at).format("DD MMM YYYY")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">Pickup Time</div>
                  <div className="col-2">:</div>
                  <div className="col-6 d-flex justify-content-end">
                    03:00 PM
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">Pickup Note</div>
                  <div className="col-2">:</div>
                  <div className="col-6 d-flex justify-content-end">
                    {value.pickup_note}
                  </div>
                </div>
              </div>
              <Divider />
              <Text
                strong
                style={{
                  fontSize: 18,
                }}
              >
                Food Details
              </Text>
              <div className="foodDetails">
                <List itemLayout="horizontal">
                  {value.foods.map((item, i) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={item.Image} />}
                        title={
                          <div className="d-flex align-items-center justify-content-between">
                            <strong>
                              {item.Name} x {item.food_qty}
                            </strong>{" "}
                            <div>{item.foodType.toUpperCase()}</div>
                          </div>
                        }
                        description={
                          <div className="d-flex align-items-center justify-content-between">
                            <span>{item.food_spice}</span>
                            <span>${item.price}</span>
                          </div>
                        }
                      />
                    </List.Item>
                  ))}
                </List>
              </div>
              <Divider />
              <Text
                strong
                style={{
                  fontSize: 18,
                }}
              >
                Guest Details
              </Text>
              <List itemLayout="horizontal">
                <List.Item>
                  <List.Item.Meta
                    title={
                      <div className="d-flex align-items-center justify-content-between">
                        <strong>{value.userName}</strong>{" "}
                      </div>
                    }
                    description={
                      <div className="d-flex align-items-center justify-content-between">
                        <span>{value.userPhone}</span>
                        <span>{value.userEmail}</span>
                      </div>
                    }
                  />
                </List.Item>
              </List>
              <Divider />
              <Text
                strong
                style={{
                  fontSize: 18,
                }}
              >
                Payment Details
              </Text>
              <div className="orderDetails">
                <div className="row">
                  <div className="col-4">SUBTOTAL</div>
                  <div className="col-2">:</div>
                  <div className="col-6 d-flex justify-content-end">
                    ${value.food_total}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">Tax 10 %</div>
                  <div className="col-2">:</div>
                  <div className="col-6 d-flex justify-content-end">
                    ${value.sales_tax}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">Tip</div>
                  <div className="col-2">:</div>
                  <div className="col-6 d-flex justify-content-end">
                    ${value.tip}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <strong>Total</strong>
                  </div>
                  <div className="col-2">:</div>
                  <div className="col-6 d-flex justify-content-end">
                    ${value.total_amount}
                  </div>
                </div>
              </div>
            </div>
          </Result>
        ))}
    </Spin>
  );
};
export default NotificationAlert;
