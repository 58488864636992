import {
  Button,
  Divider,
  Empty,
  Form,
  Input,
  List,
  Popconfirm,
  Select,
  Space,
  message,
} from "antd";
import React from "react";
import {
  AiFillFacebook,
  AiFillMinusCircle,
  AiFillTwitterCircle,
} from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareThreads } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { FaYelp } from "react-icons/fa";
import { useBranchDetailsMutation } from "../../../../services/userAuthAPI";
import { useDrawer } from "../../../../context/drawer";

export default function SocialMedia() {
  const icons = [
    {
      icons: <AiFillFacebook />,
      name: "facebook",
    },
    {
      icons: <FaInstagramSquare />,
      name: "instagram",
    },
    {
      icons: <AiFillTwitterCircle />,
      name: "twitter",
    },
    {
      icons: <BsYoutube />,
      name: "youtube",
    },
    {
      icons: <FaSquareThreads />,
      name: "thread",
    },
    {
      icons: <FcGoogle />,
      name: "google my business",
    },
    { icons: <FaYelp />, name: "yelp" },
  ];

  const [branchDetails] = useBranchDetailsMutation();
  const { drawerData, socialMediaList, setSocialMediaList } = useDrawer();

  const [form] = Form.useForm();

  const handleSubmit = async (value) => {
    const formData = new FormData();
    formData.append("name", value.name);
    formData.append("link", value.url);
    formData.append("branchId", drawerData.value.id);
    formData.append("action", "socialAdd");
    const res = await branchDetails(formData);

    if (res.data) {
      message.success("Added Successfully");
      const listSocial = socialMediaList;
      const listData = {
        id: res.data.id,
        name: value.name,
        link: value.url,
      };

      listSocial.push(listData);
      form.resetFields();
      setSocialMediaList([...listSocial]);
    }
  };

  const removeSocial = async (index, id) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("action", "removeSocial");

    const res = await branchDetails(formData);

    if (res.data) {
      message.success("Removed Successfully");
      socialMediaList.splice(index, 1);
      setSocialMediaList([...socialMediaList]);
    }
  };

  return (
    <div>
      <Divider orientation="left">Social Media List</Divider>
      <List itemLayout="horizontal">
        {socialMediaList && socialMediaList.length > 0 ? (
          socialMediaList.map((value, i) => (
            <List.Item key={i}>
              <List.Item.Meta
                avatar={
                  <h5 className={`${value.name} icons`}>
                    {
                      icons.find((valueData) => valueData.name === value.name)
                        .icons
                    }
                  </h5>
                }
                title={
                  <div className="d-flex justify-content-between">
                    <h5
                      style={{ fontSize: "16px", textTransform: "capitalize" }}
                    >
                      {value.name}
                    </h5>
                    <Popconfirm
                      title="Remove Social Media"
                      description="Are you sure to remove this Social Media?"
                      onConfirm={() => removeSocial(i, value.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <h6
                        onClick={() => removeSocial(i, value.id)}
                        style={{ color: "red", cursor: "pointer" }}
                      >
                        <AiFillMinusCircle />
                      </h6>
                    </Popconfirm>
                  </div>
                }
              />
            </List.Item>
          ))
        ) : (
          <Empty />
        )}
      </List>

      <Divider orientation="left">Add Social Media</Divider>
      <Form form={form} autoComplete="off" onFinish={handleSubmit}>
        <Space>
          <Form.Item name="name" rules={[{ required: true }]}>
            <Select
              placeholder="Select Social Media"
              style={{
                width: "100%",
              }}
            >
              {icons.map((item) =>
                socialMediaList.some(
                  (dataValue) => dataValue.name === item.name
                ) ? null : (
                  <Select.Option key={i} value={item.name}>{item.name}</Select.Option>
                )
              )}
            </Select>
          </Form.Item>
          <Form.Item name="url" rules={[{ required: true }, { type: "url" }]}>
            <Input placeholder="Social media link" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Add
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
}
