import React from "react";
import screenIcon from "./images/logo.png";

export default function Splashscreen() {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        background: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding:"0 20px"
      }}
    >
      <img style={{maxWidth:"200px", width:"100%"}} src={screenIcon} />
    </div>
  );
}
