import { Button, Form, Input,Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React,{useEffect,useState} from "react";
import { useDrawer } from "../../../context/drawer";
import { useSelector } from "react-redux";
const { Option } = Select;
import axios from "axios";
export default function ProductDetail({ setProductList, setActiveButton }) {
  const { closedDrawer } = useDrawer();
  const [categories, setCategories] = useState([]);
  const { token } = useSelector((state) => state.auth);

  const [form] = Form.useForm(); // Use Form instance

  const handleProductDetail = async (values) => {
    try {
      // Update the form data directly using the values from the form
      const formData = {
        name: values.name,
        description: values.description,
        Category_Id: values.Category_Id,
      };
      console.log(formData)

      setProductList({
        ...formData,
        category: formData.Category_Id,
        size: [],
      });
      setActiveButton("size");
    } catch (error) {
      console.error("Error handling product details:", error);
    }
  };
  const sendProductDetails = async (values) => {
    try {
      const formValues = form.getFieldsValue();
      const formDataToSend = new FormData();
      formDataToSend.append("action", "AddProduct");
      formDataToSend.append("token", token);
      formDataToSend.append("product_name", formValues.name);
      formDataToSend.append("product_desc", formValues.description);
      formDataToSend.append("Category_Id", formValues.Category_Id);
      formDataToSend.append("tax", formValues.tax);
      formDataToSend.append("product_type", formValues.product_type);

     // Generate product code based on the product name
     const productName = formValues.name;
     const productCode = productName.replace(/\s+/g, "").toUpperCase(); // Remove spaces and convert to uppercase
     formDataToSend.append("product_code", productCode);

      // Add more fields as needed

      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formDataToSend
      );

      console.log("API response:", response.data);
      const productId = response.data.product_id; // Update this based on the actual response structure
      console.log("Product ID:",productId);
      setProductList({
        name: formValues.name,
        description: formValues.description,
        Category_Id: formValues.Category_Id,
        Category_Id: formValues.tax,
        Category_Id: formValues.product_type,
        productId: productId, // Set the product ID in your state
        // ... other properties as needed
      });
        setFormData({
        name: "",
        description: "",
        Category_Id: "", // or set a default value
      });
 // Reset form fields upon successful API call
      setActiveButton("product");
    } catch (error) {
      console.error("Error sending product details to the API:", error);
      // Handle errors, display an error message, or take appropriate action.
    }
  };


  const fetchData = async () => {
    try {
    

      const response = await axios.get(
        "https://jutiepie.in/api/category.php?action=categoryFetch"
      );

      if (response) {
       
        setCategories(response.data.list);
        console.log(response.data.list);
      }
    } catch (error) {
    
      console.error("Error making get request", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Form form={form} layout="vertical" onFinish={handleProductDetail}>
      <Form.Item


          label="Category Id"
          name="Category_Id"
          rules={[{ message: "Please select the category id!" }]}
        >
          <Select style={{ width: "100%" }}>
            {Array.isArray(categories) ? (
              categories.map((category) => (
                <Option key={category.Category_Id} value={category.Category_Id}>
                  {category.Category_Type}
                </Option>
              ))
            ) : (
              <p>No categories available</p>
            )}
          </Select>
        </Form.Item>
        <Form.Item  
          label="Enter Name"
          name="name"
          rules={[{ required: true, message: "name is required" }]}
        >
          <Input placeholder="Product name" />
        </Form.Item>
        <div className="row">
          <div className="col-6">
          <Form.Item  
          label="Tax"
          name="tax"
          rules={[{ required: true, message: "name is required" }]}
        >
          <Input placeholder="Tax" />
        </Form.Item>
          </div>
          <div className="col-6">
          <Form.Item  
          label="Product Type"
          name="product_type"
          rules={[{ required: true, message: "name is required" }]}
        >
          <Input placeholder="Product Type" />
        </Form.Item>
          </div>
         
        </div>
        <Form.Item
          label="Enter Description"
          name="description"
          rules={[{ required: true, message: "description is required" }]}
        >
          <TextArea showCount rows={5} placeholder="Product description" />
        </Form.Item>
      
       
        
        <Form.Item>
          <Button
            onClick={() => closedDrawer()}
            style={{ color: "#fff", background: "#000" }}
          >
            Cancel
          </Button>
          <Button
            style={{ color: "#fff", background: "green" }}
            htmlType="submit"
            onClick={sendProductDetails}

          >
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
